





















































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { setHeadTags, buildHeadTags } from '../../lib/headTagCreate'

@Component
export default class Terms extends Vue {
  created() {
    setHeadTags(buildHeadTags.terms())
  }
}
