




















































































































































































import { Component, Vue } from 'vue-property-decorator'
import { setHeadTags, buildHeadTags } from '../../lib/headTagCreate'

@Component
export default class PrivacyTreatment extends Vue {
  created() {
    setHeadTags(buildHeadTags.privacyTreatment())
  }
}
